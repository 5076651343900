import { useSessionStorage } from './useSessionStorage';
import { useState } from 'react';

const KEY = 'VIEW_MENU';

export const useIsViewMenu = (init: boolean): [boolean, (val: boolean) => void] => {
  const [savedValue, setValue] = useSessionStorage(KEY, init);

  const setIsViewMenu = (val: boolean) => {
    setValue(val);
    setIsViewMenuState(val);
  };

  const [isViewMenu, setIsViewMenuState] = useState(savedValue);
  return [isViewMenu, setIsViewMenu];
};
