const KB = 1024;
const MB = Math.pow(KB, 2);
const GB = Math.pow(KB, 3);

export const convertUnit = (byte_size: number): [string, number] => {
  let unit = 'B';
  let applicable_unit = 1;

  if (byte_size >= KB) {
    applicable_unit = KB;
    unit = 'KB';
  }
  if (byte_size >= MB) {
    applicable_unit = MB;
    unit = 'MB';
  }
  if (byte_size >= GB) {
    applicable_unit = GB;
    unit = 'KB';
  }

  return [unit, applicable_unit];
};

export const convertSize = (byte_size: number, applicable_unit: number) => {
  let size = 0;
  if (applicable_unit) {
    size = Math.round((byte_size / applicable_unit) * 10) / 10;
  } else {
    size = byte_size;
  }
  return size;
};

export const formatSize = (byte_size: number): string => {
  const [unit, applicable_unit] = convertUnit(byte_size);

  const size = convertSize(byte_size, applicable_unit);

  return `${size}${unit}`;
};
