import { useNavigate } from 'react-router-dom';
import useSWR, { Arguments, SWRResponse } from 'swr';
import HttpStatusCode from '../const/httpStatusCode';
import { PAGE_PATH } from '../const/pagePath';
import { assembleAuthRequestHeader } from '../service/request';
import { useAuth } from './useAuth';
import { useSimpleSnackbar } from './useSimpleSnackbar';

type AuthRequestHeaders = {
  headers: {
    Authorization: string;
    Email: string;
  };
};

export const useCreateFetcher = <T>(
  url: string,
  query?: { [key: string]: string | number | boolean }
): ((requestHeaders: AuthRequestHeaders) => Promise<T>) => {
  const [, setAuth] = useAuth();
  const navigate = useNavigate();
  const pushSnackbar = useSimpleSnackbar();

  const fetcher = (requestHeaders: AuthRequestHeaders): Promise<T> => {
    return fetch(url, requestHeaders).then(async (res) => {
      if (res.ok) {
        return res.json();
      }
      let errRes = await res.json();
      if (errRes.code === HttpStatusCode.UNAUTHORIZED) {
        setAuth({});
        pushSnackbar('error', '認証エラーです、ログインし直してください');
        navigate(PAGE_PATH.LOGIN_PAGE);
      }

      throw errRes;
    });
  };
  return fetcher;
};

export const useFetch = <T>(
  key: Arguments,
  fetcher: (requestHeaders: AuthRequestHeaders) => Promise<T>
): SWRResponse<T, Error> => {
  const [auth] = useAuth();

  const fetchKey = () => {
    if (!auth || !key || (Array.isArray(key) && key.some((k) => k === null))) {
      return null;
    }
    return key;
  };

  return useSWR(fetchKey(), async () => {
    const headerOptions = assembleAuthRequestHeader(auth.token ?? '', auth.email ?? '');
    return await fetcher(headerOptions);
  });
};
