import { CreateModalProps } from '../../types/modalProps';
import { useModal } from 'react-hooks-use-modal';
import { useAuth } from '../../hooks/useAuth';
import { useSimpleSnackbar } from '../../hooks/useSimpleSnackbar';
import { useModalEffect } from '../../hooks/useModalEffect';
import '../../styles/modal.css';
import React, { useState } from 'react';
import { CreateUserType } from '../../types/http/userRequest';
import { execRequest } from '../../service/request';
import { API } from '../../const/api';

export const CreateUserModal: React.FC<CreateModalProps> = (props) => {
  const { isOpenModal, setIsOpenModal, mutate } = props;
  const [Modal, open, close, isOpen] = useModal('root', {
    preventScroll: true,
  });

  const [auth] = useAuth();
  const pushSnackbar = useSimpleSnackbar();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState('');

  useModalEffect(isOpenModal, setIsOpenModal, open, close, isOpen);

  const changeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const changePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  }

  const clickCreateUser = async () => {

    // validate
    if (!password) {
      pushSnackbar("warning", "パスワードを設定してください");
      return;
    }

    if (!email) {
      pushSnackbar("warning", "メールアドレスを設定してください");
      return;
    }

    if (password.indexOf(' ') !== -1) {
      pushSnackbar("warning", "パスワードに空文字を含めないでください");
      return;
    }

    const body: CreateUserType = {
      email: email,
      password: password,
    };

    const result = await execRequest(API.USER_INSERT, {
      token: auth.token,
      email: auth.email,
      method: 'POST',
      body: body,
    });

    if (!result.ok) {
      setErrorMessage(`作成に失敗しました:${result.error_message}`);
      return;
    }

    if (mutate) {
      mutate();
    }
    pushSnackbar('success', 'ユーザーの作成に成功しました');
    setPassword("");
    setEmail("");
    close();
  };

  return (
    <Modal>
      <div className="modal-wrapper">
        <h4 className="text-primary">ユーザーの作成</h4>
        <div className="form form-white mb-4">
          <div className="text-secondary">メール</div>
          <input type="email" className="form-control" placeholder="sample@sample.com" onChange={changeEmail} />
        </div>
        <div className='form form-white mb-4'>
          <div className='text-secondary'>パスワード</div>
          <input type="text" className='form-control' placeholder='password' onChange={changePassword} />
        </div>
        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
        <div className="d-flex justify-content-between">
          <button className="btn btn-secondary" onClick={close}>
            キャンセル
          </button>
          <button className="btn btn-primary" onClick={clickCreateUser}>
            作成
          </button>
        </div>
      </div>
    </Modal>
  );
};
