import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { SnackbarProvider } from './contexts/SnackbarContext';
import TopPage from './pages';
import AboutPage from './pages/about';
import CmsUserPage from './pages/cmsUser';
import LoginPage from './pages/login';
import UserPage from './pages/user';
import UserVideoPage from './pages/userVideo';
import VideoPage from './pages/video';
import { SimpleSnackbar } from './components/simpleSnackbar';
import UserVideoDetailPage from './pages/userVideo/detail';
import UserVideoAddPage from './pages/userVideo/add';

const App = () => {
  return (
    <SnackbarProvider>
      <SimpleSnackbar />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<TopPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/cms/user" element={<CmsUserPage />} />
          <Route path="/user" element={<UserPage />} />
          <Route path="/user/video" element={<UserVideoPage />} />
          <Route path="/user/video/detail" element={<UserVideoDetailPage />} />
          <Route path="/user/video/add" element={<UserVideoAddPage />} />
          <Route path="/video" element={<VideoPage />} />
          <Route path="/about" element={<AboutPage />} />
        </Routes>
      </BrowserRouter>
    </SnackbarProvider>
  );
};

export default App;
