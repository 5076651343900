import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TableDelete } from '../components/atom/tableDelete';
import { TableEdit } from '../components/atom/tableEdit';
import { Layout } from '../components/layout';
import { CreateUserModal } from '../components/templates/createUserModal';
import { DeleteUserModal } from '../components/templates/deleteUserModal';
import { UpdateUserModal } from '../components/templates/updateUserModal';
import { API } from '../const/api';
import { PAGE_PATH } from '../const/pagePath';
import { useCreateFetcher, useFetch } from '../hooks/useFetch';
import { useSimpleSnackbar } from '../hooks/useSimpleSnackbar';
import { parse, stringify } from '../service/jsonutil';
import '../styles/table.css';
import { User, UserList } from '../types/http/userResponse';

const userDefault: User = {
  id: '',
  email: '',
  password: "",
  create_time: '',
  update_time: '',
};

const UserPage = () => {
  const api = API.USER_LIST;
  const fetcher = useCreateFetcher<UserList>(api);
  const { data, mutate } = useFetch<UserList>([api], fetcher);
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [isOpenUpdateModal, setIsOpenUpdateModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [selectUser, setSelectUser] = useState<User>(userDefault);
  const pushSnackbar = useSimpleSnackbar();
  const navigate = useNavigate();

  const openCreateModal = () => {
    setIsOpenCreateModal(true);
  };

  const openDeleteModal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { target } = e.currentTarget.dataset;
    if (!target) {
      pushSnackbar('error', '削除に失敗しました');
      return;
    }

    const user = parse<User>(target);
    setSelectUser(user);
    setIsOpenDeleteModal(true);
  };

  const openUpdateModal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { target } = e.currentTarget.dataset;
    if (!target) {
      pushSnackbar('error', '編集に失敗しました');
      return;
    }

    const user = parse<User>(target);
    setSelectUser(user);
    setIsOpenUpdateModal(true);
  };

  const moveUserVideoDetail = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const { target } = e.currentTarget.dataset;
    if (!target) {
      pushSnackbar('error', '詳細に飛べませんでした');
      return;
    }

    const user = parse<User>(target);
    const userId = user.id;
    navigate(`${PAGE_PATH.USER_VIDEO_DETAIL_PAGE}?user_id=${userId}`);
  };

  return (
    <Layout title="UESR PAGE">
      <div>
        <CreateUserModal isOpenModal={isOpenCreateModal} setIsOpenModal={setIsOpenCreateModal} mutate={mutate} />
        <UpdateUserModal
          isOpenModal={isOpenUpdateModal}
          setIsOpenModal={setIsOpenUpdateModal}
          mutate={mutate}
          before={selectUser}
        />
        <DeleteUserModal
          isOpenModal={isOpenDeleteModal}
          setIsOpenModal={setIsOpenDeleteModal}
          mutate={mutate}
          target={selectUser}
        />
        <div className="card">
          <div className="card-header d-flex justify-content-between align-items-center">
            <button className="btn btn-primary" onClick={openCreateModal}>
              ユーザーの追加
            </button>
          </div>
          <div className="card-body">
            <div>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">email</th>
                    <th scope='col'>password</th>
                    <th scope="col">video</th>
                    <th scope="col">Create</th>
                    <th scope="col">Update</th>
                    <th scope="col">Edit</th>
                    <th scope="col">Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.user_list &&
                    data.user_list.map((value, key) => (
                      <tr key={key}>
                        <th scope="row">{key}</th>
                        <td>{value.email}</td>
                        <td>{value.password}</td>
                        <td>
                          <button
                            className="btn btn-primary"
                            data-target={stringify(value)}
                            onClick={moveUserVideoDetail}
                          >
                            見れる動画
                          </button>
                        </td>
                        <td>{value.create_time}</td>
                        <td>{value.update_time}</td>
                        <TableEdit target={value} onClick={openUpdateModal} />
                        <TableDelete target={value} onClick={openDeleteModal} />
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default UserPage;
