import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { APP_ENV } from '../const/appEnv';

const app_env = APP_ENV;

const firebaseConfig = {
  apiKey: app_env.FIREBASE_API_KEY,
  authDomain: app_env.FIREBASE_AUTH_DOMAIN,
  projectId: app_env.FIREBASE_PROJECT_ID,
  storageBucket: app_env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: app_env.FIREBASE_MESSAGE_SENDER_ID,
  appId: app_env.FIREBASE_APP_ID,
  measurementId: app_env.FIREBASE_MEASUREMENT_ID,
};

// init
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleAuthProvider = new GoogleAuthProvider();

export { app, auth, googleAuthProvider };
