import { signInWithPopup } from 'firebase/auth';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { auth, googleAuthProvider } from '../service/firebase';
import '../styles/login.css';

const LoginPage = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [, setAuthSession] = useAuth();

  const loginWithGoogle = () => {
    // sign in
    signInWithPopup(auth, googleAuthProvider)
      .then(async (userCredential) => {
        const token = await userCredential.user.getIdToken(true);
        // setAuthSession
        setAuthSession({
          token: token,
          email: userCredential.user.email ?? '',
        });
        navigate('/');
      })
      .catch((error) => {
        console.error(error);
        setErrorMessage('Googleでのログインに失敗しました');
      });
  };

  return (
    <section className="vh-100 gradient-custom">
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5">
            <div className="card bg-dark text-white login-card">
              <div className="card-body p-5 text-center">
                <div className="mb-md-5 mt-md-4 pb-5">
                  <h2 className="fw-bold mb-2 text-uppercase">cms login</h2>
                  <p className="text-white-50 mb-5">Please enter your login and password</p>

                  {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

                  <button className="btn btn-outline-light btn-lg px-5" onClick={loginWithGoogle}>
                    Google Login
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginPage;
