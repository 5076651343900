import { useLocation, useNavigate } from 'react-router-dom';
import { Layout } from '../../components/layout';
import { API } from '../../const/api';
import { PAGE_PATH } from '../../const/pagePath';
import { useCreateFetcher, useFetch } from '../../hooks/useFetch';
import { UserVideoAddPageResponse } from '../../types/http/userVideoResponse';
import '../../styles/table.css';
import { Image } from '../../components/atom/image';
import React, { useState } from 'react';
import { Video } from '../../types/http/videoResponse';
import { parse, stringify } from '../../service/jsonutil';
import { useSimpleSnackbar } from '../../hooks/useSimpleSnackbar';
import { CreateUserVideoModal } from '../../components/templates/createUserVideoModal';
import { CreateUserVideo } from '../../types/createUserVideo';

const videoDefault: Video = {
  id: '',
  name: '',
  size: 0,
  video_path: '',
  thumb_path: '',
  thumb_url: '',
  create_time: '',
  update_time: '',
};

const createUserVideoDefault: CreateUserVideo = {
  user_id: '',
  video: videoDefault,
};

const UserVideoAddPage = () => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const user_id = query.get('user_id');
  const navigate = useNavigate();
  const pushSnackbar = useSimpleSnackbar();

  const api = `${API.VIDEO_USER_ADD_PAGE}?user_id=${user_id}`;
  const fetcher = useCreateFetcher<UserVideoAddPageResponse>(api);
  const { data, mutate } = useFetch<UserVideoAddPageResponse>([api], fetcher);

  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [createUserVideo, setCreateUserVideo] = useState<CreateUserVideo>(createUserVideoDefault);

  const moveDetailPage = () => {
    navigate(`${PAGE_PATH.USER_VIDEO_DETAIL_PAGE}?user_id=${user_id}`);
  };

  const openCreateModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const { target } = e.currentTarget.dataset;
    if (!target) {
      pushSnackbar('error', '動画が見つかりませんでした');
      return;
    }

    const video = parse<Video>(target);
    setCreateUserVideo({
      user_id: user_id ?? '',
      video: video,
    });
    setIsOpenCreateModal(true);
  };

  return (
    <Layout title="User Video Create">
      <div>
        <CreateUserVideoModal
          isOpenModal={isOpenCreateModal}
          setIsOpenModal={setIsOpenCreateModal}
          mutate={mutate}
          target={createUserVideo}
        />
        <div className="alert alert-primary">ユーザー: {data?.user_email}</div>
        <div className="card">
          <div className="card-header d-flex justify-content-start align-items-center">
            <button className="btn btn-secondary me-2" onClick={moveDetailPage}>
              戻る
            </button>
          </div>
          <div className="card-body">
            <div>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Status</th>
                    <th scope="col">Name</th>
                    <th scope="col">thumb</th>
                    <th scope="col">Create</th>
                    <th scope="col">Update</th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.video_list &&
                    data.video_list.map((value, key) => (
                      <tr key={key}>
                        <th scope="row">{key}</th>
                        <td>
                          {value.is_added ? (
                            <button className="btn btn-secondary disabled">追加済み</button>
                          ) : (
                            <button
                              className="btn btn-primary"
                              data-target={stringify(value.mst_video)}
                              onClick={openCreateModal}
                            >
                              追加
                            </button>
                          )}
                        </td>
                        <td>{value.mst_video.name}</td>
                        <td>
                          <Image src={value.mst_video.thumb_url} className="table-thumb-image" />
                        </td>
                        <td>{value.mst_video.create_time}</td>
                        <td>{value.mst_video.update_time}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default UserVideoAddPage;
