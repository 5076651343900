import { useModal } from 'react-hooks-use-modal';
import React, { useEffect, useState } from 'react';
import '../../styles/createCmsUserModal.css';
import { execRequest } from '../../service/request';
import { API } from '../../const/api';
import { useAuth } from '../../hooks/useAuth';
import { CreateCmsUserType } from '../../types/http/cmsUserRequest';
import { CmsUser } from '../../types/http/cmsUserResponse';
import { KeyedMutator } from 'swr';
import { useSimpleSnackbar } from '../../hooks/useSimpleSnackbar';

type CreateCmsUserModalProps = {
  isOpenModal: boolean;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  mutate: KeyedMutator<CmsUser[]>;
};

export const CreateCmsUserModal: React.FC<CreateCmsUserModalProps> = (props) => {
  const { isOpenModal, setIsOpenModal, mutate } = props;
  const [Modal, open, close, isOpen] = useModal('root', {
    preventScroll: true,
  });

  const [auth] = useAuth();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const pushSnackbar = useSimpleSnackbar();

  useEffect(() => {
    if (isOpenModal) {
      open();
    } else {
      close();
    }
  }, [close, isOpenModal, open]);

  useEffect(() => {
    if (!isOpen) {
      setErrorMessage('');
      setIsOpenModal(false);
    }
  }, [isOpen, setIsOpenModal]);

  const changeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const changeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const clickCreateCmsUser = async () => {
    const body: CreateCmsUserType = {
      name: name,
      email: email,
    };

    let result = await execRequest(API.CMS_USER_INSERT, {
      token: auth.token,
      email: auth.email,
      method: 'POST',
      body: body,
    });

    if (!result.ok) {
      setErrorMessage(`作成に失敗しました:${result.error_message}`);
      return;
    }

    mutate();
    pushSnackbar('success', 'ユーザーの作成に成功しました');
    close();
  };

  return (
    <Modal>
      <div className="create-cms-user-modal-wrapper">
        <h4 className="text-primary">ユーザーの追加</h4>

        <div className="form form-white mb-4">
          <div className="text-secondary">名前</div>
          <input type="text" className="form-control" placeholder="名前" onChange={changeName} />
        </div>

        <div className="form form-white mb-4">
          <div className="text-secondary">メール</div>
          <input type="email" className="form-control" placeholder="sample@sample.com" onChange={changeEmail} />
        </div>

        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
        <div className="d-flex justify-content-between">
          <button className="btn btn-secondary" onClick={close}>
            キャンセル
          </button>
          <button className="btn btn-primary" onClick={clickCreateCmsUser}>
            作成
          </button>
        </div>
      </div>
    </Modal>
  );
};
