import React, { useState } from 'react';
import { useModal } from 'react-hooks-use-modal';
import { API } from '../../const/api';
import { useAuth } from '../../hooks/useAuth';
import { useSimpleSnackbar } from '../../hooks/useSimpleSnackbar';
import { execRequest, uploadFileS3 } from '../../service/request';
import { CreateVideoType } from '../../types/http/videoRequest';
import { CreateModalProps } from '../../types/modalProps';
import '../../styles/modal.css';
import { getUniqueStr } from '../../service/random';
import { SignedUrl } from '../../types/http/cmsVideoResopnse';
import { useModalEffect } from '../../hooks/useModalEffect';

export const CreateVideoModal: React.FC<CreateModalProps> = (props) => {
  const { isOpenModal, setIsOpenModal, mutate } = props;
  const [Modal, open, close, isOpen] = useModal('root', {
    preventScroll: true,
  });
  const pushSnackbar = useSimpleSnackbar();

  const [auth] = useAuth();
  const [name, setName] = useState('');
  const [videoFile, setVideoFile] = useState<File>();
  const [thumbFile, setThumbFile] = useState<File>();

  useModalEffect(isOpenModal, setIsOpenModal, open, close, isOpen);

  const changeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const changeVideoFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setVideoFile(e.target.files[0]);
    } else {
      setVideoFile(undefined);
    }
  };

  const changeThumbFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setThumbFile(e.target.files[0]);
    } else {
      setThumbFile(undefined);
    }
  };

  const createVideo = async () => {
    if (!videoFile || !thumbFile) {
      pushSnackbar('error', 'ファイルが選択されていません');
      return;
    }

    const uniqueStr = getUniqueStr();

    const videoPath = `video/${uniqueStr}/${videoFile.name}`;
    const thumbPath = `thumb/${uniqueStr}/${thumbFile.name}`;

    // get upload presigned url
    const videoPresignedURLResponse = await execRequest<SignedUrl>(API.VIDEO_MST_UPLOAD_VIDEO_URL, {
      token: auth.token,
      email: auth.email,
      method: 'GET',
      query: {
        path: videoPath,
        content_type: videoFile.type,
      },
    });
    if (!videoPresignedURLResponse.ok) {
      pushSnackbar('error', `動画のアップロードに失敗しました:${videoPresignedURLResponse.error_message}`);
      return;
    }

    const videoPresignedUrl = videoPresignedURLResponse.body?.url!;
    uploadFileS3(videoPresignedUrl, videoFile);

    const thumbPresignedURLResponse = await execRequest<SignedUrl>(API.VIDEO_MST_UPLOAD_THUMB_URL, {
      token: auth.token,
      email: auth.email,
      method: 'GET',
      query: {
        path: thumbPath,
        content_type: thumbFile.type,
      },
    });
    if (!thumbPresignedURLResponse.ok) {
      pushSnackbar('error', `サムネイルのアップロードに失敗しました:${thumbPresignedURLResponse.error_message}`);
      return;
    }

    const thumbPresignedURL = thumbPresignedURLResponse.body?.url!;
    uploadFileS3(thumbPresignedURL, thumbFile);

    const body: CreateVideoType = {
      name: name,
      size: videoFile.size,
      video_path: videoPath,
      thumb_path: thumbPath,
    };

    const result = await execRequest(API.VIDEO_MST_INSERT, {
      token: auth.token,
      email: auth.email,
      method: 'POST',
      body: body,
    });

    if (!result.ok) {
      pushSnackbar('error', '動画の追加に失敗しました');
      return;
    }

    if (mutate) {
      mutate();
    }
    pushSnackbar('success', '動画の追加に成功しました');
    close();
  };

  return (
    <Modal>
      <div className="modal-wrapper">
        <h4 className="text-primary">動画の作成</h4>

        <div className="form form-white mb-4">
          <div className="text-secondary">動画名</div>
          <input type="text" className="form-control" placeholder="サンプル動画" onChange={changeName} />
        </div>
        <div className="form form-white mb-4">
          <label htmlFor="video" className="form-label text-secondary">
            動画ファイル
          </label>
          <input type="file" className="form-control" id="video" onChange={changeVideoFile} />
        </div>
        <div className="form form-white mb-4">
          <label htmlFor="thumb" className="form-label text-secondary">
            サムネ画像
          </label>
          <input type="file" className="form-control" id="thumb" onChange={changeThumbFile} />
        </div>
        <div className="d-flex justify-content-between">
          <button className="btn btn-secondary" onClick={close}>
            キャンセル
          </button>
          <button className="btn btn-primary" onClick={createVideo}>
            作成
          </button>
        </div>
      </div>
    </Modal>
  );
};
