import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Header } from './header';
import '../styles/layout.css';
import { useNavigate } from 'react-router-dom';
import { useIsViewMenu } from '../hooks/useViewMenu';

type LayoutPropsType = {
  title: string;
  children: JSX.Element | string;
};

export const Layout: React.FC<LayoutPropsType> = (props) => {
  const { title, children } = props;
  const [isViewMenu, setIsViewMenu] = useIsViewMenu(true);

  const navigate = useNavigate();

  const moveVideoPage = () => {
    navigate('/video');
  };

  const moveUserPage = () => {
    navigate('/user');
  };

  const moveCmsUserPage = () => {
    navigate('/cms/user');
  };

  return (
    <div className="wrapper">
      <HelmetProvider>
        <Helmet>
          <title>Video Transporter CMS | {title}</title>
        </Helmet>
      </HelmetProvider>

      <div className="layout-content">
        <Header header="Video Transporter CMS" isViewMenu={isViewMenu} setIsViewMenu={setIsViewMenu}></Header>
        <div className="layout-wrapper">
          {isViewMenu && (
            <div className="layout-navigation">
              <h3 className="my-3">Menu</h3>
              <div
                className={
                  window.location.pathname === '/video' ? 'layout-navigation-elem-active' : 'layout-navigation-elem'
                }
                onClick={moveVideoPage}
              >
                - VIDEO
              </div>
              <div
                className={
                  window.location.pathname === '/user' ? 'layout-navigation-elem-active' : 'layout-navigation-elem'
                }
                onClick={moveUserPage}
              >
                - USER
              </div>
              <div
                className={
                  window.location.pathname === '/cms/user' ? 'layout-navigation-elem-active' : 'layout-navigation-elem'
                }
                onClick={moveCmsUserPage}
              >
                - CMS USER
              </div>
            </div>
          )}
          <div className="layout-inner-content">
            <h3 className="my-3 text-primary text-center">{title}</h3>
            {children}
          </div>
        </div>
        {/* <Footer footer={"copyright yun_sunjin"}></Footer> */}
      </div>
    </div>
  );
};
