import { useModal } from 'react-hooks-use-modal';
import { useModalEffect } from '../../hooks/useModalEffect';
import { Video } from '../../types/http/videoResponse';
import { DetailModalProps } from '../../types/modalProps';
import '../../styles/modal.css';
import { useEffect, useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { downloadFileV2, execRequest } from '../../service/request';
import { SignedUrl } from '../../types/http/cmsVideoResopnse';
import { API } from '../../const/api';
import { useSimpleSnackbar } from '../../hooks/useSimpleSnackbar';
import { getExtFromFilePath } from '../../service/file';
import { useDownloadProgress } from '../../hooks/useDownloadProgress';

export const DownloadVideoModal: React.FC<DetailModalProps<Video>> = (props) => {
  const { isOpenModal, setIsOpenModal, target } = props;
  const [Modal, open, close, isOpen] = useModal('root', {
    preventScroll: true,
  });
  const [auth] = useAuth();
  const pushSnackbar = useSimpleSnackbar();

  const [downloadStatus, setDownloadStatus] = useState('');
  const [downloadProgress, setDownloadProgress] = useDownloadProgress();

  useModalEffect(isOpenModal, setIsOpenModal, open, close, isOpen);

  useEffect(() => {
    setDownloadStatus('');
  }, [isOpen]);

  const download = async () => {
    // download url
    if (!target || !target.id) {
      pushSnackbar('error', '動画の取得に失敗しました');
      return;
    }

    const resp = await execRequest<SignedUrl>(API.VIDEO_MST_DOWNLOAD_VIDEO_URL, {
      token: auth.token,
      email: auth.email,
      method: 'GET',
      query: {
        video_id: target.id,
      },
    });

    if (!resp.ok) {
      pushSnackbar('error', `動画の取得に失敗しました:${resp.error_message}`);
      return;
    }

    const dlUrl = resp.body?.url;
    if (!dlUrl) {
      pushSnackbar('error', '動画の取得に失敗しました');
      return;
    }

    const ext = getExtFromFilePath(target.video_path);
    const filename = `${target.name}.${ext}`;
    await downloadFileV2(dlUrl, filename, setDownloadStatus, setDownloadProgress);

    setTimeout(() => {
      close();
    }, 1500);
  };

  return (
    <Modal>
      <div className="modal-wrapper">
        <h4 className="text-primary">動画のダウンロード</h4>
        <h5>{target.name}をダウンロードしますか?</h5>

        {downloadStatus === 'start' && (
          <div>
            <h6 className="text-primary">ダウンロード中です...</h6>
            <h6>
              {downloadProgress.received_length}/{downloadProgress.content_length}
            </h6>
          </div>
        )}
        {downloadStatus === 'finish' && <h6 className="text-success">ダウンロード完了!</h6>}
        {downloadStatus === 'error' && <h6 className="text-warning">ダウンロードに失敗</h6>}

        <div className="d-flex justify-content-between">
          <button className="btn btn-secondary" onClick={close}>
            キャンセル
          </button>
          <button className="btn btn-primary" onClick={download}>
            ダウンロード
          </button>
        </div>
      </div>
    </Modal>
  );
};
