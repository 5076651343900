import { Layout } from '../components/layout';
import { API } from '../const/api';
import { useCreateFetcher, useFetch } from '../hooks/useFetch';
import '../styles/table.css';
import { UserVideo } from '../types/http/userVideoResponse';

const UserVideoPage = () => {
  const api = API.VIDEO_USER_LIST;
  const fetcher = useCreateFetcher<UserVideo[]>(api);
  const { data } = useFetch<UserVideo[]>([api], fetcher);

  return (
    <Layout title="USER VIDEO PAGE">
      <div>
        <div className="card">
          <div className="card-header d-flex justify-content-between align-item-center">
            <button className="btn btn-primary">ユーザーの作成</button>
          </div>
          <div className="card-body">
            <div>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">user_id</th>
                    <th scope="col">video_id</th>
                    <th scope="col">ダウンロード回数</th>
                    <th scope="col">最大ダウンロード可能数</th>
                    <th scope="col">Create</th>
                    <th scope="col">Update</th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.map((value, key) => (
                      <tr key={key}>
                        <th scope="row">{key}</th>
                        <td>{value.user_id}</td>
                        <td>{value.video_id}</td>
                        <td>{value.download_count}</td>
                        <td>{value.max_download_count}</td>
                        <td>{value.create_time}</td>
                        <td>{value.update_time}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default UserVideoPage;
