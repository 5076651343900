import { useEffect } from 'react';
import { useModal } from 'react-hooks-use-modal';
import { API } from '../../const/api';
import { useAuth } from '../../hooks/useAuth';
import { useSimpleSnackbar } from '../../hooks/useSimpleSnackbar';
import { execRequest } from '../../service/request';
import { DeleteVideoType } from '../../types/http/videoRequest';
import { Video } from '../../types/http/videoResponse';
import { DeleteModalProps } from '../../types/modalProps';

export const DeleteVideoModal: React.FC<DeleteModalProps<Video>> = (props) => {
  const { isOpenModal, setIsOpenModal, mutate, target } = props;
  const [Modal, open, close, isOpen] = useModal('root', {
    preventScroll: true,
  });

  const [auth] = useAuth();
  const pushSnackbar = useSimpleSnackbar();

  useEffect(() => {
    if (isOpenModal) {
      open();
    } else {
      close();
    }
  }, [close, isOpenModal, open]);

  useEffect(() => {
    if (!isOpen) {
      setIsOpenModal(false);
    }
  }, [isOpen, setIsOpenModal]);

  const clickDelete = async () => {
    const body: DeleteVideoType = {
      video_id_list: [target.id],
    };

    const result = await execRequest(API.VIDEO_MST_DELETE, {
      token: auth.token,
      email: auth.email,
      method: 'POST',
      body: body,
    });

    if (!result.ok) {
      pushSnackbar('error', `動画の削除に失敗しました:${result.error_message}`);
      return;
    }

    if (mutate) {
      mutate();
    }
    pushSnackbar('success', '動画の削除に成功しました');
    close();
  };

  return (
    <Modal>
      <div className="modal-wrapper">
        <h4 className="text-primary">動画の削除</h4>
        <div className="d-flex justify-content-between">
          <button className="btn btn-secondary" onClick={close}>
            キャンセル
          </button>
          <button className="btn btn-danger" onClick={clickDelete}>
            削除
          </button>
        </div>
      </div>
    </Modal>
  );
};
