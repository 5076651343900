import { AiOutlineDelete } from 'react-icons/ai';
import { stringify } from '../../service/jsonutil';

type TableDeleteType = {
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  target?: any;
};

export const TableDelete: React.FC<TableDeleteType> = (props) => {
  return (
    <td className="table-icon-cell" onClick={props.onClick} data-target={stringify(props.target)}>
      <div className="d-flex justify-content-center">
        <AiOutlineDelete />
      </div>
    </td>
  );
};
