import React, { useState } from 'react';
import { Layout } from '../components/layout';
import { CreateVideoModal } from '../components/templates/createVideoModal';
import { API } from '../const/api';
import { useCreateFetcher, useFetch } from '../hooks/useFetch';
import { useSimpleSnackbar } from '../hooks/useSimpleSnackbar';
import { Video } from '../types/http/videoResponse';
import '../styles/table.css';
import { AiOutlineDelete, AiOutlineDownload } from 'react-icons/ai';
import { DeleteVideoModal } from '../components/templates/deleteVideoModal';
import { DownloadVideoModal } from '../components/templates/downloadVideoModal';
import { Image } from '../components/atom/image';
import { formatSize } from '../service/calcByte';

const videoDefault: Video = {
  id: '',
  name: '',
  size: 0,
  video_path: '',
  thumb_path: '',
  thumb_url: '',
  create_time: '',
  update_time: '',
};

const VideoPage = () => {
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isOpenDownloadModal, setIsOpenDownloadModal] = useState(false);
  const [selectVideo, setSelectVideo] = useState<Video>(videoDefault);

  const pushSnackbar = useSimpleSnackbar();

  const api = API.VIDEO_MST_LIST;
  const fetcher = useCreateFetcher<Video[]>(api);
  const { data, mutate } = useFetch<Video[]>([api], fetcher);

  const openCreateModal = () => {
    setIsOpenCreateModal(true);
  };

  const openDeleteModal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { id } = e.currentTarget.dataset;
    if (!id) {
      pushSnackbar('error', '削除するIDが見つかりませんでした');
      return;
    }
    const video = generateVideo(id);
    setSelectVideo(video);

    setIsOpenDeleteModal(true);
  };

  const openDownloadModal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { mst } = e.currentTarget.dataset;

    if (!mst) {
      pushSnackbar('error', 'ダウンロードに失敗しました');
      return;
    }

    const video = JSON.parse(mst) as Video;

    setSelectVideo(video);
    setIsOpenDownloadModal(true);
  };

  const generateVideo = (id: string): Video => {
    return {
      id: id,
      name: '',
      size: 0,
      video_path: '',
      thumb_path: '',
      thumb_url: '',
      create_time: '',
      update_time: '',
    };
  };

  return (
    <Layout title="VIDEO PAGE">
      <div>
        <CreateVideoModal isOpenModal={isOpenCreateModal} setIsOpenModal={setIsOpenCreateModal} mutate={mutate} />
        <DeleteVideoModal
          isOpenModal={isOpenDeleteModal}
          setIsOpenModal={setIsOpenDeleteModal}
          mutate={mutate}
          target={selectVideo}
        />
        <DownloadVideoModal
          isOpenModal={isOpenDownloadModal}
          setIsOpenModal={setIsOpenDownloadModal}
          target={selectVideo}
        />
        <div className="card">
          <div className="card-header d-flex justify-content-between align-items-center">
            <button className="btn btn-primary" onClick={openCreateModal}>
              動画の追加
            </button>
          </div>
          <div className="card-body">
            <div>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">size</th>
                    <th scope="col">thumb</th>
                    <th scope="col">Create</th>
                    <th scope="col">Update</th>
                    <th scope="col" className="table-edit-column">
                      DL
                    </th>
                    <th scope="col" className="table-delete-column">
                      Delete
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.map((value, key) => (
                      <tr key={key}>
                        <th scope="row">{key}</th>
                        <td>{value.name}</td>
                        <td>{formatSize(value.size)}</td>
                        <td>
                          <Image src={value.thumb_url} className={'table-thumb-image'} />
                        </td>
                        <td>{value.create_time}</td>
                        <td>{value.update_time}</td>
                        <td className="table-icon-cell">
                          <div
                            className="d-flex justify-content-center"
                            data-mst={JSON.stringify(value)}
                            onClick={openDownloadModal}
                          >
                            <AiOutlineDownload />
                          </div>
                        </td>
                        <td
                          className="table-icon-cell"
                          onClick={openDeleteModal}
                          data-id={value.id}
                          data-name={value.name}
                        >
                          <div className="d-flex justify-content-center">
                            <AiOutlineDelete />
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default VideoPage;
