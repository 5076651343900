import { UserVideo } from '../../types/http/userVideoResponse';
import { UpdateModalProps } from '../../types/modalProps';
import '../../styles/modal.css';
import { useModal } from 'react-hooks-use-modal';
import { useAuth } from '../../hooks/useAuth';
import { useSimpleSnackbar } from '../../hooks/useSimpleSnackbar';
import React, { useEffect, useState } from 'react';
import { useModalEffect } from '../../hooks/useModalEffect';
import { UpdateUserVideoMaxDownloadCount } from '../../types/http/userVideoRequest';
import { execRequest } from '../../service/request';
import { API } from '../../const/api';

export const UpdateUserVideoModal: React.FC<UpdateModalProps<UserVideo>> = (props) => {
  const { isOpenModal, setIsOpenModal, mutate, before } = props;
  const [Modal, open, close, isOpen] = useModal('root', {
    preventScroll: true,
  });

  const [auth] = useAuth();
  const pushSnackbar = useSimpleSnackbar();

  const [maxDownloadCount, setMaxDownloadCount] = useState(before.max_download_count);

  useModalEffect(isOpenModal, setIsOpenModal, open, close, isOpen);

  useEffect(() => {
    setMaxDownloadCount(before.max_download_count);
  }, [before]);

  const changeMaxDownloadCount = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMaxDownloadCount(Number(e.target.value));
  };

  const clickUpdate = async () => {
    if (maxDownloadCount <= 0) {
      pushSnackbar('error', '最大ダウンロード可能数は1以上を指定してください');
      return;
    }

    const body: UpdateUserVideoMaxDownloadCount = {
      user_video_id: before.id,
      max_download_count: maxDownloadCount,
    };

    const result = await execRequest(API.VIDEO_USER_UPDATE_MAX_DOWNLOAD_COUNT, {
      token: auth.token,
      email: auth.email,
      method: 'POST',
      body: body,
    });

    if (!result.ok) {
      pushSnackbar('error', `編集に失敗しました:${result.error_message}`);
      return;
    }

    if (mutate) {
      mutate();
    }

    pushSnackbar('success', 'ユーザー動画の編集に成功しました');
    close();
  };

  return (
    <Modal>
      <div className="modal-wrapper">
        <h4 className="text-primary">ユーザー動画設定の編集</h4>
        <div className="form form-white mb-4">
          <div className="text-secondary">最大ダウンロード可能回数</div>
          <input type="number" className="form-control" value={maxDownloadCount} onChange={changeMaxDownloadCount} />
        </div>
        <div className="d-flex justify-content-between">
          <button className="btn btn-secondary" onClick={close}>
            キャンセル
          </button>
          <button className="btn btn-primary" onClick={clickUpdate}>
            編集
          </button>
        </div>
      </div>
    </Modal>
  );
};
