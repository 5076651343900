import React, { useEffect, useState } from 'react';
import { useModal } from 'react-hooks-use-modal';
import { API } from '../../const/api';
import { useAuth } from '../../hooks/useAuth';
import { useModalEffect } from '../../hooks/useModalEffect';
import { useSimpleSnackbar } from '../../hooks/useSimpleSnackbar';
import { execRequest } from '../../service/request';
import { UpdateUserType } from '../../types/http/userRequest';
import { User } from '../../types/http/userResponse';
import { UpdateModalProps } from '../../types/modalProps';

export const UpdateUserModal: React.FC<UpdateModalProps<User>> = (props) => {
  const { isOpenModal, setIsOpenModal, mutate, before } = props;
  const [Modal, open, close, isOpen] = useModal('root', {
    preventScroll: true,
  });

  const [auth] = useAuth();
  const pushSnackbar = useSimpleSnackbar();

  const [email, setEmail] = useState<string>(before.email);
  const [password, setPassword] = useState<string>(before.password);

  useModalEffect(isOpenModal, setIsOpenModal, open, close, isOpen);

  useEffect(() => {
    setEmail(before.email);
    setPassword(before.password);
  }, [before]);

  const changeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const changePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  }

  const clickUpdateUser = async () => {

    // validate
    if (!email) {
      pushSnackbar("warning", "メールアドレスを入力してください");
      return;
    }

    if (!password) {
      pushSnackbar("warning", "パスワードを入力してください");
      return;
    }

    if (password.indexOf(' ') !== -1) {
      pushSnackbar("warning", "パスワードに空文字を含めないでください");
      return;
    }

    const body: UpdateUserType = {
      user_id: before.id,
      email: email === before.email ? undefined : email,
      password: password === before.password ? undefined : password,
    };

    const result = await execRequest(API.USER_UPDATE, {
      token: auth.token,
      email: auth.email,
      method: 'POST',
      body: body,
    });

    if (!result.ok) {
      pushSnackbar('error', `編集に失敗しました:${result.error_message}`);
      return;
    }

    if (mutate) {
      mutate();
    }

    pushSnackbar('success', 'ユーザーの編集に成功しました');
    setEmail("");
    setPassword("");

    close();
  };

  return (
    <Modal>
      <div className="modal-wrapper">
        <h4 className="text-primary">ユーザーの編集</h4>
        <div className="form form-white mb-4">
          <div className="text-secondary">メール</div>
          <input
            type="email"
            className="form-control"
            placeholder="sample@sample.com"
            value={email}
            onChange={changeEmail}
          />
        </div>
        <div className='form form-white mb-4'>
          <div className='text-secondary'>パスワード</div>
          <input type="text" className='form-control' placeholder='パスワード' value={password} onChange={changePassword} />
        </div>
        <div className="d-flex justify-content-between">
          <button className="btn btn-secondary" onClick={close}>
            キャンセル
          </button>
          <button className="btn btn-primary" onClick={clickUpdateUser}>
            編集
          </button>
        </div>
      </div>
    </Modal>
  );
};
