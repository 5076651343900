export type DataType = {
  token?: string;
  email?: string;
  body?: { [key: string]: string | number | boolean | string[] | number[] } | FormData | Blob;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  contentType?: string;
  query?: { [key: string]: string | number | boolean };
  mode?: 'cors' | 'navigate' | 'no-cors' | 'same-origin';
};

export type FetchOptions = {
  body?: string | FormData | null;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  headers: Headers;
  mode: 'cors' | 'navigate' | 'no-cors' | 'same-origin';
};

export type Response<T> = {
  ok: boolean;
  status: number;
  body?: T;
  error_message?: string;
};

export const downloadFileV2 = async (
  url: string,
  filename: string,
  setDownloadStatus: (status: string) => void,
  setDownloadProgress?: (contentLength: number, receivedLength: number) => void
) => {
  setDownloadStatus('start');

  let response = await fetch(url);

  const reader = response.body?.getReader();

  const contentLengthStr = response.headers.get('Content-Length');
  const contentLength = Number(contentLengthStr!);

  let receivedLength = 0;
  let chunks = [];
  while (true) {
    const readResult = await reader?.read();
    const done = readResult?.done;
    const value = readResult?.value;

    if (done) {
      break;
    }

    if (value) {
      chunks.push(value);
      if (value.length) {
        receivedLength += value.length;
      }
    }

    if (setDownloadProgress) {
      setDownloadProgress(contentLength, receivedLength);
    }
  }

  let chunksAll = new Uint8Array(receivedLength);
  let position = 0;
  for (let chunk of chunks) {
    chunksAll.set(chunk, position);
    position += chunk.length;
  }

  let blob = new Blob(chunks);
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.click();

  setDownloadStatus('finish');
};

export const execRequest = async <T>(url: string, data: DataType) => {
  const { body, method, contentType = 'application/json', token, email } = data;

  const createRequestBody = () => {
    if (!body) {
      return null;
    }
    if (contentType === 'multipart/form-data') {
      return body as FormData;
    }
    return JSON.stringify(body);
  };

  const authHeader = assembleAuthRequestHeader(token ?? '', email ?? '');
  const reqeustHeaders = new Headers({
    ...authHeader.headers,
  });

  if (contentType !== 'multipart/form-data') {
    reqeustHeaders.append('Content-Type', contentType);
  }

  reqeustHeaders.append('Access-Control-Allow-Origin', '*');
  // reqeustHeaders.append("Access-Control-Allow-Credentials", "true");
  // reqeustHeaders.append("Hoge-Hoge", "hoge");

  const options: FetchOptions = {
    body: createRequestBody(),
    method: method,
    headers: reqeustHeaders,
    mode: data.mode ?? 'cors',
  };

  const getQueryString = (query: { [key: string]: string | number | boolean }) => {
    const keyValuePairs = [];
    for (const key in query) {
      keyValuePairs.push(`${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`);
    }
    return keyValuePairs.join('&');
  };

  // build uri
  let uri = url;
  if (data.query) {
    uri = `${uri}?${getQueryString(data.query)}`;
  }

  const result = await fetch(uri, options)
    .then(async (result) => {
      if (!result.ok) {
        let message = await result.text();
        console.error(message);
        return {
          ok: result.ok,
          status: result.status,
          error_message: message,
        } as Response<T>;
      }

      return {
        ok: result.ok,
        status: result.status,
        body: await result.json(),
      } as Response<T>;
    })
    .catch((error) => {
      console.error(error);
      return {
        ok: false,
        status: 500,
        error_message: 'internal server error',
      } as Response<T>;
    });

  return result;
};

export const assembleAuthRequestHeader = (token: string, email: string) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      Email: email,
    },
  };
};

export const uploadFileS3 = async (signedUrl: string, file: File) => {
  const response = await fetch(signedUrl, {
    method: 'PUT',
    body: file,
  });
  console.log('s3 upload res is ', response);
};
