import { useState } from 'react';
import { DownloadProgressType } from '../types/downloadProgress';

export const useDownloadProgress = (): [
  DownloadProgressType,
  (contentLength: number, receivedLength: number) => void
] => {
  const defaultDownloadProgress: DownloadProgressType = {
    received_length: 0,
    content_length: 0,
  };

  const [downloadProgress, set] = useState(defaultDownloadProgress);

  const setDownloadProgress = (contentLength: number, receivedLength: number) => {
    set({
      content_length: contentLength,
      received_length: receivedLength,
    });
  };

  return [downloadProgress, setDownloadProgress];
};
