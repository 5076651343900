import { useModal } from 'react-hooks-use-modal';
import { API } from '../../const/api';
import { useAuth } from '../../hooks/useAuth';
import { useModalEffect } from '../../hooks/useModalEffect';
import { useSimpleSnackbar } from '../../hooks/useSimpleSnackbar';
import { execRequest } from '../../service/request';
import { UserIDType } from '../../types/http/userRequest';
import { User } from '../../types/http/userResponse';
import { DeleteModalProps } from '../../types/modalProps';

export const DeleteUserModal: React.FC<DeleteModalProps<User>> = (props) => {
  const { isOpenModal, setIsOpenModal, mutate, target } = props;
  const [Modal, open, close, isOpen] = useModal('root', {
    preventScroll: true,
  });

  const [auth] = useAuth();
  const pushSnackbar = useSimpleSnackbar();

  useModalEffect(isOpenModal, setIsOpenModal, open, close, isOpen);

  const clickDelete = async () => {
    const body: UserIDType = {
      user_id: target.id,
    };

    const result = await execRequest(API.USER_DELETE, {
      token: auth.token,
      email: auth.email,
      method: 'POST',
      body: body,
    });

    if (!result.ok) {
      pushSnackbar('error', `ユーザーの削除に失敗しました:${result.error_message}`);
      return;
    }

    if (mutate) {
      mutate();
    }

    pushSnackbar('success', 'ユーザーの削除に成功しました');
    close();
  };

  return (
    <Modal>
      <div className="modal-wrapper">
        <h4 className="text-primary">ユーザーの削除</h4>
        <div className="d-flex justify-content-between">
          <button className="btn btn-secondary" onClick={close}>
            キャンセル
          </button>
          <button className="btn btn-danger" onClick={clickDelete}>
            削除
          </button>
        </div>
      </div>
    </Modal>
  );
};
