import { APP_ENV } from './appEnv';

export const API = {
  USER_LIST: `${APP_ENV.API_URL}/user/list`,
  USER_INSERT: `${APP_ENV.API_URL}/user/insert`,
  USER_UPDATE: `${APP_ENV.API_URL}/user/update`,
  USER_DELETE: `${APP_ENV.API_URL}/user/delete`,
  USER_DETAIL: `${APP_ENV.API_URL}/user/detail`,
  CMS_USER_LIST: `${APP_ENV.API_URL}/cms/user/list`,
  CMS_USER_INSERT: `${APP_ENV.API_URL}/cms/user/insert`,
  CMS_USER_UPDATE: `${APP_ENV.API_URL}/cms/user/update`,
  CMS_USER_DELETE: `${APP_ENV.API_URL}/cms/user/delete`,
  VIDEO_MST_LIST: `${APP_ENV.API_URL}/video/mst/list`,
  VIDEO_MST_INSERT: `${APP_ENV.API_URL}/video/mst/insert`,
  VIDEO_MST_DELETE: `${APP_ENV.API_URL}/video/mst/delete`,
  VIDEO_MST_UPLOAD_VIDEO_URL: `${APP_ENV.API_URL}/video/mst/upload_video_url`,
  VIDEO_MST_DOWNLOAD_VIDEO_URL: `${APP_ENV.API_URL}/video/mst/download_video_url`,
  VIDEO_MST_UPLOAD_THUMB_URL: `${APP_ENV.API_URL}/video/mst/upload_thumb_url`,
  VIDEO_USER_LIST: `${APP_ENV.API_URL}/video/user/list`,
  VIDEO_USER_LIST_BY_USER_ID: `${APP_ENV.API_URL}/video/user/list/by_userid`,
  VIDEO_USER_DETAIL_BY_USER_ID: `${APP_ENV.API_URL}/video/user/detail/by_userid`,
  VIDEO_USER_ADD_PAGE: `${APP_ENV.API_URL}/video/user/add/page`,
  VIDEO_USER_INSERT: `${APP_ENV.API_URL}/video/user/insert`,
  VIDEO_USER_DELETE: `${APP_ENV.API_URL}/video/user/delete`,
  VIDEO_USER_UPDATE_MAX_DOWNLOAD_COUNT: `${APP_ENV.API_URL}/video/user/update_max_download_count`,
} as const;
