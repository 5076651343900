import { useContext } from 'react';
import { SnackbarContext } from '../contexts/SnackbarContext';

export const useSimpleSnackbar = () => {
  const { setSnackbarInfo } = useContext(SnackbarContext);

  const pushSnackbar = (level: 'info' | 'success' | 'warning' | 'error', message?: string, closeTime?: number) => {
    setSnackbarInfo({ isOpen: true, level: level, message: message ?? '' });

    setTimeout(() => {
      setSnackbarInfo({
        isOpen: false,
        level: 'info',
        message: 'sample message',
      });
    }, closeTime ?? 5 * 1000);
  };

  return pushSnackbar;
};
