import { UpdateModalProps } from '../../types/modalProps';
import { useModal } from 'react-hooks-use-modal';
import '../../styles/modal.css';
import { UpdateCmsUserType } from '../../types/http/cmsUserRequest';
import { useSimpleSnackbar } from '../../hooks/useSimpleSnackbar';
import React, { useEffect, useState } from 'react';
import { CmsUser } from '../../types/http/cmsUserResponse';
import { execRequest } from '../../service/request';
import { API } from '../../const/api';
import { useAuth } from '../../hooks/useAuth';

export const UpdateCmsUserModal: React.FC<UpdateModalProps<CmsUser>> = (props) => {
  const { isOpenModal, setIsOpenModal, mutate, before } = props;
  const [Modal, open, close, isOpen] = useModal('root', {
    preventScroll: true,
  });
  const pushSnackbar = useSimpleSnackbar();
  const [name, setName] = useState(before.name);
  const [email, setEmail] = useState(before.email);
  const [auth] = useAuth();

  useEffect(() => {
    if (isOpenModal) {
      open();
    } else {
      close();
    }
  }, [close, isOpenModal, open]);

  useEffect(() => {
    if (!isOpen) {
      setIsOpenModal(false);
    }
  }, [isOpen, setIsOpenModal]);

  useEffect(() => {
    setName(before.name);
    setEmail(before.email);
  }, [before]);

  const changeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const changeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const clickUpdateCmsUser = async () => {
    const body: UpdateCmsUserType = {
      cms_user_id: before.id,
      name: name,
      email: email,
    };

    const result = await execRequest<any>(API.CMS_USER_UPDATE, {
      token: auth.token,
      email: auth.email,
      method: 'POST',
      body: body,
    });

    if (!result.ok) {
      pushSnackbar('error', `編集に失敗しました:${result.error_message}`);
      return;
    }

    if (mutate) {
      mutate();
    }
    pushSnackbar('success', 'ユーザーの編集に成功しました');
    close();
  };

  return (
    <Modal>
      <div className="modal-wrapper">
        <h4 className="text-primary">ユーザーの編集</h4>
        <div className="form form-white mb-4">
          <div className="text-secondary">名前</div>
          <input type="text" className="form-control" placeholder="名前" onChange={changeName} value={name} />
        </div>

        <div className="form form-white mb-4">
          <div className="text-secondary">メール</div>
          <input
            type="email"
            className="form-control"
            placeholder="sample@sample.com"
            onChange={changeEmail}
            value={email}
          />
        </div>

        <div className="d-flex justify-content-between">
          <button className="btn btn-secondary" onClick={close}>
            キャンセル
          </button>
          <button className="btn btn-primary" onClick={clickUpdateCmsUser}>
            編集
          </button>
        </div>
      </div>
    </Modal>
  );
};
