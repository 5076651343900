import { AiOutlineVideoCamera, AiOutlineMenu } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { PAGE_PATH } from '../const/pagePath';
import { useAuth } from '../hooks/useAuth';

import '../styles/header.css';

type HeaderPropsType = {
  header: string | JSX.Element;
  isViewMenu: boolean;
  setIsViewMenu: (val: boolean) => void;
};

export const Header: React.FC<HeaderPropsType> = (props) => {
  const navigate = useNavigate();

  const { header, isViewMenu, setIsViewMenu } = props;
  const [auth, setAuth] = useAuth();

  const moveTop = () => {
    navigate(PAGE_PATH.TOP_PAGE);
  };

  const clickMenuIcon = () => {
    setIsViewMenu(!isViewMenu);
  };

  const logout = () => {
    setAuth({});
    navigate(PAGE_PATH.LOGIN_PAGE);
  };

  return (
    <div className="bg-dark bg-gradient header">
      <div className="header-content">
        <div className="header-icon-wrapper" onClick={clickMenuIcon}>
          <AiOutlineMenu className="header-icon"></AiOutlineMenu>
        </div>
        <div className="header-icon-wrapper">
          <AiOutlineVideoCamera className="header-icon" />
        </div>
        <div className="header-title" onClick={moveTop}>
          {header}
        </div>
      </div>
      <div className="header-content">
        <div className="m-2">{auth.email}</div>
        <button className="btn btn-outline-light btn-sm px-5" onClick={logout}>
          Logout
        </button>
      </div>
    </div>
  );
};
