import { useLocation, useNavigate } from 'react-router-dom';
import { Layout } from '../../components/layout';
import { API } from '../../const/api';
import { useCreateFetcher, useFetch } from '../../hooks/useFetch';
import { UserVideo, UserVideoDetailByUserIDResponse } from '../../types/http/userVideoResponse';
import { Image } from '../../components/atom/image';
import '../../styles/table.css';
import { PAGE_PATH } from '../../const/pagePath';
import { TableEdit } from '../../components/atom/tableEdit';
import { TableDelete } from '../../components/atom/tableDelete';
import React, { useState } from 'react';
import { useSimpleSnackbar } from '../../hooks/useSimpleSnackbar';
import { parse } from '../../service/jsonutil';
import { UpdateUserVideoModal } from '../../components/templates/updateUserVideoModal';
import { DeleteUserVideoModal } from '../../components/templates/deleteUserVideoModal';

const userVideoDefault: UserVideo = {
  id: '',
  user_id: '',
  video_id: '',
  download_count: 0,
  max_download_count: 0,
  create_time: '',
  update_time: '',
};

const UserVideoDetailPage = () => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const user_id = query.get('user_id');

  const api = `${API.VIDEO_USER_DETAIL_BY_USER_ID}?user_id=${user_id}`;
  const fetcher = useCreateFetcher<UserVideoDetailByUserIDResponse>(api);
  const { data, mutate } = useFetch<UserVideoDetailByUserIDResponse>([api], fetcher);
  const navigate = useNavigate();

  const [isOpenUpdateModal, setIsOpenUpdateModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [selectUserVideo, setSelectUserVideo] = useState(userVideoDefault);

  const pushSnackbar = useSimpleSnackbar();

  const moveUserPage = () => {
    navigate(PAGE_PATH.USER_PAGE);
  };

  const moveAddPage = () => {
    navigate(`${PAGE_PATH.USER_VIDEO_ADD_PAGE}?user_id=${user_id}`);
  };

  const openUpdateModal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { target } = e.currentTarget.dataset;
    if (!target) {
      pushSnackbar('error', '編集に失敗しました');
      return;
    }
    const userVideo = parse<UserVideo>(target);
    setSelectUserVideo(userVideo);
    setIsOpenUpdateModal(true);
  };

  const openDeleteModal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { target } = e.currentTarget.dataset;
    if (!target) {
      pushSnackbar('error', '削除に失敗しました');
      return;
    }
    const userVideo = parse<UserVideo>(target);
    setSelectUserVideo(userVideo);
    setIsOpenDeleteModal(true);
  };

  return (
    <Layout title="ユーザーが視聴できる動画リスト">
      <div>
        <UpdateUserVideoModal
          isOpenModal={isOpenUpdateModal}
          setIsOpenModal={setIsOpenUpdateModal}
          mutate={mutate}
          before={selectUserVideo}
        />
        <DeleteUserVideoModal
          isOpenModal={isOpenDeleteModal}
          setIsOpenModal={setIsOpenDeleteModal}
          mutate={mutate}
          target={selectUserVideo}
        />
        <div className="alert alert-primary">ユーザー: {data?.user_email}</div>
        <div className="card">
          <div className="card-header d-flex justify-content-start align-items-center">
            <button className="btn btn-secondary me-2" onClick={moveUserPage}>
              戻る
            </button>
            <button className="btn btn-primary me-2" onClick={moveAddPage}>
              追加
            </button>
          </div>
          <div className="card-body">
            <div>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">name</th>
                    <th scope="col">thumb</th>
                    <th scope="col">ダウンロード回数</th>
                    <th scope="col">最大ダウンロード可能数</th>
                    <th scope="col">Create</th>
                    <th scope="col">Update</th>
                    <th scope="col">Edit</th>
                    <th scope="col">Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.user_video_list &&
                    data.user_video_list.map((value, key) => (
                      <tr key={key}>
                        <th scope="row">{key}</th>
                        <td>{value.video_name}</td>
                        <td>
                          <Image src={value.thumb_url} className="table-thumb-image" />
                        </td>
                        <td>{value.download_count}</td>
                        <td>{value.max_download_count}</td>
                        <td>{value.create_time}</td>
                        <td>{value.update_time}</td>
                        <TableEdit target={value} onClick={openUpdateModal} />
                        <TableDelete target={value} onClick={openDeleteModal} />
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default UserVideoDetailPage;
