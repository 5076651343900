import React, { useState } from 'react';
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';
import { Layout } from '../components/layout';
import { CreateCmsUserModal } from '../components/templates/createCmsUserModal';
import { API } from '../const/api';
import { useCreateFetcher, useFetch } from '../hooks/useFetch';
import { CmsUser } from '../types/http/cmsUserResponse';
import '../styles/table.css';
import { UpdateCmsUserModal } from '../components/templates/updateCmsUserModal';
import { useSimpleSnackbar } from '../hooks/useSimpleSnackbar';
import { DeleteCmsUserModal } from '../components/templates/deleteCmsUserModal';

const cmsUserDefault: CmsUser = {
  id: '',
  name: '',
  email: '',
  create_time: '',
  update_time: '',
};

const CmsUserPage = () => {
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [isOpenUpdateModal, setIsOpenUpdateModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [selectCmsUser, setSelectCmsUser] = useState<CmsUser>(cmsUserDefault);
  const pushSnackbar = useSimpleSnackbar();

  const openCreateModal = () => {
    setIsOpenCreateModal(true);
  };

  const openUpdateModal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { id, name, email } = e.currentTarget.dataset;

    const cmsUser = generateCmsUser(id, name, email);
    if (!id) {
      pushSnackbar('error', '編集するIDが見つかりませんでした');
      return;
    }

    setSelectCmsUser(cmsUser);
    setIsOpenUpdateModal(true);
  };

  const openDeleteModal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { id, name, email } = e.currentTarget.dataset;
    const cmsUser = generateCmsUser(id, name, email);
    if (!cmsUser.id) {
      pushSnackbar('error', '削除するIDが見つかりませんでした');
      return;
    }
    setSelectCmsUser(cmsUser);
    setIsOpenDeleteModal(true);
  };

  const generateCmsUser = (id?: string, name?: string, email?: string): CmsUser => {
    return {
      id: id ?? '',
      name: name ?? '',
      email: email ?? '',
      create_time: '',
      update_time: '',
    };
  };

  const api = API.CMS_USER_LIST;
  const fetcher = useCreateFetcher<CmsUser[]>(api);
  const { data, mutate } = useFetch<CmsUser[]>([api], fetcher);

  return (
    <Layout title="CMS ユーザー一覧">
      <div>
        <CreateCmsUserModal isOpenModal={isOpenCreateModal} setIsOpenModal={setIsOpenCreateModal} mutate={mutate} />
        <UpdateCmsUserModal
          isOpenModal={isOpenUpdateModal}
          setIsOpenModal={setIsOpenUpdateModal}
          before={selectCmsUser}
          mutate={mutate}
        />
        <DeleteCmsUserModal
          isOpenModal={isOpenDeleteModal}
          setIsOpenModal={setIsOpenDeleteModal}
          target={selectCmsUser}
          mutate={mutate}
        />
        <div className="card">
          <div className="card-header d-flex justify-content-between align-items-center">
            <button className="btn btn-primary" onClick={openCreateModal}>
              ユーザーの追加
            </button>
          </div>
          <div className="card-body">
            <div>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Create</th>
                    <th scope="col">Update</th>
                    <th scope="col" className="table-edit-column">
                      Edit
                    </th>
                    <th scope="col" className="table-delete-column">
                      Delete
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.map((value, key) => (
                      <tr key={key}>
                        <th scope="row">{key}</th>
                        <td>{value.name}</td>
                        <td>{value.email}</td>
                        <td>{value.create_time}</td>
                        <td>{value.update_time}</td>

                        <td className="table-icon-cell">
                          <div
                            className="d-flex justify-content-center"
                            onClick={openUpdateModal}
                            data-id={value.id}
                            data-name={value.name}
                            data-email={value.email}
                          >
                            <AiOutlineEdit />
                          </div>
                        </td>
                        <td
                          className="table-icon-cell"
                          onClick={openDeleteModal}
                          data-id={value.id}
                          data-name={value.name}
                          data-email={value.email}
                        >
                          <div className="d-flex justify-content-center">
                            <AiOutlineDelete />
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CmsUserPage;
