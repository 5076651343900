import { useContext } from 'react';
import { SnackbarContext } from '../contexts/SnackbarContext';
import '../styles/simpleSnackbar.css';
import { AiOutlineClose } from 'react-icons/ai';

export const SimpleSnackbar = () => {
  const { snackbarInfo, setSnackbarInfo } = useContext(SnackbarContext);

  const close = () => {
    setSnackbarInfo({ isOpen: false, level: 'info', message: 'smaple' });
  };

  return (
    <div className="simple-snackbar-wrapper">
      {snackbarInfo.isOpen && (
        <div className={`simple-snackbar simple-snackbar-${snackbarInfo.level}`}>
          <div className="simple-snackbar-content">
            <div>{snackbarInfo.message}</div>
          </div>
          <div className="simple-snackbar-close" onClick={close}>
            <AiOutlineClose></AiOutlineClose>
          </div>
        </div>
      )}
    </div>
  );
};
