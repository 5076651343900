import { Layout } from '../components/layout';

const TopPage = (): JSX.Element => {
  return (
    <Layout title="Top">
      <div>
        <div className="alert alert-primary">hello world top page</div>
        <div className="alert alert-primary">hello world top page</div>
        <div className="alert alert-primary">hello world top page</div>
        <div className="alert alert-primary">hello world top page</div>
        <div className="alert alert-primary">hello world top page</div>
        <div className="alert alert-primary">hello world top page</div>
        <div className="alert alert-primary">hello world top page</div>
        <div className="alert alert-primary">hello world top page</div>
        <div className="alert alert-primary">hello world top page</div>
        <div className="alert alert-primary">hello world top page</div>
        <div className="alert alert-primary">hello world top page</div>
        <div className="alert alert-primary">hello world top page</div>
        <div className="alert alert-primary">hello world top page</div>
        <div className="alert alert-primary">hello world top page</div>
        <div className="alert alert-primary">hello world top page</div>
        <div className="alert alert-primary">hello world top page</div>
        <div className="alert alert-primary">hello world top page</div>
        <div className="alert alert-primary">hello world top page</div>
      </div>
    </Layout>
  );
};

export default TopPage;
