import { useModal } from 'react-hooks-use-modal';
import { useAuth } from '../../hooks/useAuth';
import { useModalEffect } from '../../hooks/useModalEffect';
import { useSimpleSnackbar } from '../../hooks/useSimpleSnackbar';
import { DetailModalProps } from '../../types/modalProps';
import '../../styles/modal.css';
import React, { useState } from 'react';
import { CreateUserVideo } from '../../types/createUserVideo';
import { execRequest } from '../../service/request';
import { API } from '../../const/api';

export const CreateUserVideoModal: React.FC<DetailModalProps<CreateUserVideo>> = (props) => {
  const { isOpenModal, setIsOpenModal, mutate, target } = props;
  const [Modal, open, close, isOpen] = useModal('root', {
    preventScroll: true,
  });
  useModalEffect(isOpenModal, setIsOpenModal, open, close, isOpen);
  const [maxDownloadCount, setMaxDownloadCount] = useState(0);
  const pushSnackbar = useSimpleSnackbar();
  const [auth] = useAuth();

  const changeMaxDownloadCount = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMaxDownloadCount(Number(e.target.value));
  };

  const addUserVideo = async () => {
    if (maxDownloadCount <= 0) {
      pushSnackbar('warning', '最大ダウンロード回数は1以上を選択してください');
      return;
    }

    const resp = await execRequest(API.VIDEO_USER_INSERT, {
      token: auth.token,
      email: auth.email,
      method: 'POST',
      body: {
        user_id: target.user_id,
        video_id: target.video.id,
        max_download_count: maxDownloadCount,
      },
    });

    if (!resp.ok) {
      pushSnackbar('error', `ユーザーへの動画登録に失敗しました:${resp.error_message}`);
      return;
    }

    pushSnackbar('success', 'ユーザーへの動画登録に成功しました');

    if (mutate) {
      mutate();
    }
    close();
  };

  return (
    <Modal>
      <div className="modal-wrapper">
        <h4 className="text-primary">動画登録</h4>
        <h4>title: {target.video.name}</h4>
        <div className="form form-white mb-4">
          <div className="text-secondary">最大ダウンロード可能数</div>
          <input type="number" className="form-control" onChange={changeMaxDownloadCount} />
        </div>
        <div className="d-flex justify-content-between">
          <button className="btn btn-secondary" onClick={close}>
            キャンセル
          </button>
          <button className="btn btn-primary" onClick={addUserVideo}>
            追加
          </button>
        </div>
      </div>
    </Modal>
  );
};
