import { useEffect } from 'react';

export const useModalEffect = (
  isOpenModal: boolean,
  setIsOpenModal: (isOpen: boolean) => void,
  open: () => void,
  close: () => void,
  isOpen: boolean
) => {
  useEffect(() => {
    if (isOpenModal) {
      open();
    } else {
      close();
    }
  }, [close, isOpenModal, open]);

  useEffect(() => {
    if (!isOpen) {
      setIsOpenModal(false);
    }
  }, [isOpen, setIsOpenModal]);
};
