import React from 'react';
import { AiOutlineEdit } from 'react-icons/ai';
import { stringify } from '../../service/jsonutil';

type TableEditType = {
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  target?: any;
};

export const TableEdit: React.FC<TableEditType> = (props) => {
  return (
    <td className="table-icon-cell" onClick={props.onClick} data-target={stringify(props.target)}>
      <div className="d-flex justify-content-center">
        <AiOutlineEdit />
      </div>
    </td>
  );
};
