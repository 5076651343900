import { useEffect } from 'react';
import { useModal } from 'react-hooks-use-modal';
import { DeleteModalProps } from '../../types/modalProps';
import '../../styles/modal.css';
import { DeleteCmsUserType } from '../../types/http/cmsUserRequest';
import { execRequest } from '../../service/request';
import { API } from '../../const/api';
import { useAuth } from '../../hooks/useAuth';
import { useSimpleSnackbar } from '../../hooks/useSimpleSnackbar';
import { CmsUser } from '../../types/http/cmsUserResponse';

export const DeleteCmsUserModal: React.FC<DeleteModalProps<CmsUser>> = (props) => {
  const { isOpenModal, setIsOpenModal, mutate, target } = props;
  const [Modal, open, close, isOpen] = useModal('root', {
    preventScroll: true,
  });
  const [auth] = useAuth();
  const pushSnackbar = useSimpleSnackbar();

  useEffect(() => {
    if (isOpenModal) {
      open();
    } else {
      close();
    }
  }, [close, isOpenModal, open]);

  useEffect(() => {
    if (!isOpen) {
      setIsOpenModal(false);
    }
  }, [isOpen, setIsOpenModal]);

  const clickDelete = async () => {
    const body: DeleteCmsUserType = {
      cms_user_id: target.id,
    };

    const result = await execRequest(API.CMS_USER_DELETE, {
      token: auth.token,
      email: auth.email,
      method: 'POST',
      body: body,
    });

    if (!result.ok) {
      pushSnackbar('error', `ユーザーの削除に失敗しました:${result.error_message}`);
      return;
    }
    if (mutate) {
      mutate();
    }
    pushSnackbar('success', 'ユーザーの削除に成功しました');
    close();
  };

  return (
    <Modal>
      <div className="modal-wrapper">
        <h4 className="text-primary">ユーザーの削除</h4>
        <div className="d-flex justify-content-between">
          <button className="btn btn-secondary" onClick={close}>
            キャンセル
          </button>
          <button className="btn btn-danger" onClick={clickDelete}>
            削除
          </button>
        </div>
      </div>
    </Modal>
  );
};
