import { Layout } from '../components/layout';
import { useSimpleSnackbar } from '../hooks/useSimpleSnackbar';

const AboutPage = () => {
  const pushSnackbar = useSimpleSnackbar();
  const click = () => {
    pushSnackbar('info');
  };

  return (
    <Layout title="About">
      <div>
        <div className="alert alert-primary">hello world about page</div>
        <button className="btn btn-primary" onClick={click}>
          snackbar
        </button>
      </div>
    </Layout>
  );
};

export default AboutPage;
